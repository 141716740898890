// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"apply-frontend@v0.15.1"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://14003d81bb6b7bcb13a075fcd1c05811@o4504764418818048.ingest.us.sentry.io/4507058574131200",
  release: `apply-frontend@${process.env.VERSION_TAG}`,
  environment: process.env.SENTRY_ENVIRONMENT,

  // Add optional integrations for additional features
  integrations: (defaultIntegrations) => [
    ...defaultIntegrations,
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      mask: [
        'input[type="password"]',
        'input[name="ssn"]',
        'input[name="tax_id"]',
        '[mask-me="true"]',
      ],
    }),
    Sentry.browserTracingIntegration(),
  ],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
